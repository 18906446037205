const CourseDetail = () => import("@/views/CourseDetailView.vue");
const CourseList = () => import("@/views/CourseListView.vue");
const Courses = () => import("@/views/CoursesView.vue");
const Home = () => import("@/views/HomeView.vue");
const Planner = () => import("@/views/PlannerView.vue");
const Seminars = () => import("@/views/SeminarsView.vue");
const Wishlist = () => import("@/views/WishlistView.vue");

export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/courses",
    component: Courses,
    children: [
      {
        path: "/courses",
        name: "planner",
        component: Planner,
      },
      {
        path: "/courses/seminars",
        name: "seminars",
        redirect: { name: "seminar-overview" },
      },
    ],
  },
  {
    path: "/seminar-overview",
    name: "seminar-overview",
    component: Seminars,
  },
  {
    path: "/specializations",
    name: "specializations",
    component: Seminars,
  },
  {
    path: "/list",
    name: "courselist",
    component: CourseList,
  },
  {
    path: "/course/:slug",
    name: "course",
    component: CourseDetail,
  },
  {
    path: "/wishlist",
    name: "wishlist",
    component: Wishlist,
  },
];
