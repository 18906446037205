<template>
  <notifications
    position="top center"
    :width="notificationWidth"
    style="top: 90px; position: fixed"
  >
    <template #body="props">
      <div id="baspo-toastr">
        <div class="toast-container">
          <div
            class="toast"
            style="display: block"
            :class="getTypeClass(props.item.type)"
          >
            <div v-if="props.item.title" class="toast-title">
              {{ props.item.title }}
            </div>
            <div v-if="props.item.text" class="toast-message">
              {{ props.item.text }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>
<script setup>
import { computed } from "vue";

const getTypeClass = (type) => {
  if (type === "error") {
    return "toast-error";
  } else {
    return "toast-success";
  }
};

const notificationWidth = computed(() => {
  if (window.innerWidth > 768) {
    return "760";
  } else {
    return "100%";
  }
});
</script>
<style lang="scss">
#baspo-toastr {
  .toast-container {
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0 1em;
    }

    width: 760px;
    margin: 10px auto 0;
    z-index: 999999;
    cursor: pointer;

    .toast {
      box-shadow: none;
      opacity: 1;
      padding: 15px 15px 15px 50px;
      color: #fff;
      border-radius: 4.25px;
    }

    .toast-error {
      background-color: #de9f9b;
      border: 2px solid #a32629;
      background-image: url("@/assets/icons/error.svg") !important;
      background-repeat: no-repeat;
      background-position: 15px 15px;

      &:hover {
        background-color: #de7f7a;
      }
    }

    .toast-success {
      background-color: #add9ad;
      border: 2px solid #3b8842;
      background-image: url("@/assets/icons/check.svg") !important;
      background-position: 15px 15px;
      background-repeat: no-repeat;

      &:hover {
        background-color: #86bf86;
      }
    }
  }
}
</style>
