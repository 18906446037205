<template>
  <component :is="icon" v-if="icon" :key="name" class="svg-icon" />
  <BIcon v-else :icon="name" :pack="pack" :size="size" />
</template>

<script setup>
import { ref, watch, defineComponent, onMounted } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  pack: {
    type: String,
    default: "fas",
  },
  size: {
    type: String,
    default: "",
  },
});

const icon = ref(null);

onMounted(() => {
  loadIcon();
});

const loadIcon = async () => {
  try {
    const svg = await import(`../assets/icons/${props.name}.svg`);
    icon.value = defineComponent(svg);
  } catch {
    icon.value = null;
  }
};

watch(() => props.name, loadIcon);
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
