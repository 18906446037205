import { defineStore } from "pinia";
import _ from "lodash";
import { useStorage } from "@vueuse/core";

import {
  getSeminars,
  getCourses,
  getModules,
} from "@/services/backend.service";

export const useWishlistStore = defineStore("Wishlist", {
  state: () => {
    return {
      // local storage data
      savedCourses: useStorage("Wishlist.savedCourses", []),
      savedSeminars: useStorage("Wishlist.savedSeminars", []),

      // data for display
      wishlistCourses: [],
      wishlistSeminars: [],

      courses: [],
      seminars: [],
      modules: [],
    };
  },
  getters: {
    wishlistCount(state) {
      return state.savedCourses.length + state.savedSeminars.length;
    },
    coursesOnly(state) {
      return _.filter(state.courses, (course) => course.visible);
    },
    seminarsOnWishlist(state) {
      return state.wishlistSeminars
        .map((seminar) => {
          return state.seminars[seminar];
        })
        .filter((seminar) => seminar?.seminarType === "seminar");
    },
    specializationsOnWishlist(state) {
      return state.wishlistSeminars
        .map((seminar) => {
          return state.seminars[seminar];
        })
        .filter((seminar) => seminar?.seminarType === "specialization");
    },
    duplicateCourseNotifications(state) {
      let notifications = [];
      this.seminarsOnWishlist.forEach((seminar) => {
        seminar.courses.forEach((course) => {
          if (state.wishlistCourses.indexOf(course) !== -1) {
            notifications.push({
              course: state.courses[course],
              seminar: seminar,
            });
          }
        });
      });
      return notifications;
    },
    isCourseOnWishlist() {
      return (course) => this.savedCourses.indexOf(course.id) !== -1;
    },
    isSeminarOnWishlist() {
      return (seminar) => this.savedSeminars.indexOf(seminar.id) !== -1;
    },
  },
  actions: {
    async loadWishlist(courses, seminars) {
      const [seminarsResponse, coursesResponse, modulesResponse] =
        await Promise.all([getSeminars(), getCourses(), getModules()]);

      this.setData({
        seminars: seminarsResponse,
        courses: coursesResponse,
        modules: modulesResponse,
        wishlistCourses: courses,
        wishlistSeminars: seminars,
      });
    },

    async loadWishlistLocalStorage() {
      await this.loadWishlist(this.savedCourses, this.savedSeminars);
    },

    setData(data) {
      data.seminars.forEach((seminar) => {
        this.seminars[seminar.id] = seminar;
      });

      data.modules.forEach((module) => {
        this.modules[module.id] = {
          ...module,
          visible: true,
          courses: [],
        };
      });

      // put courses into dictionary, key is the id of the course
      // also add references to courses on state.modules
      this.courses = {};
      data.courses.map((course) => {
        if (course.module) {
          this.modules[course.module].courses.push(course.id);
        }
        this.courses[course.id] = {
          ...course,
          visible: true,
        };
      });

      this.wishlistCourses = data.wishlistCourses;
      this.wishlistSeminars = data.wishlistSeminars;

      // clear out old courses/seminar
      this.savedCourses = _.filter(
        this.savedCourses,
        (course) => course in this.courses,
      );
      this.savedSeminars = _.filter(
        this.savedSeminars,
        (seminar) => seminar in this.seminars,
      );

      this.updateFilter();
    },

    toggleCourseOnWishlist(id) {
      let index = this.savedCourses.indexOf(id);
      if (index !== -1) {
        this.savedCourses.splice(index, 1);
      } else {
        this.savedCourses.push(id);
      }
      this.wishlistCourses = this.savedCourses;
      this.updateFilter();
    },

    toggleSeminarOnWishlist(id) {
      let index = this.savedSeminars.indexOf(id);
      if (index !== -1) {
        this.savedSeminars.splice(index, 1);
      } else {
        this.savedSeminars.push(id);
      }
      this.wishlistSeminars = this.savedSeminars;
    },

    moveToLocalStorage() {
      this.savedCourses = this.wishlistCourses;
      this.savedSeminars = this.wishlistSeminars;
    },

    updateFilter() {
      let wishlistCourses = new Set(this.wishlistCourses);
      let visibleModules = new Set();
      _.forEach(this.courses, (course, index) => {
        // course is on wishlist, make it visible
        if (wishlistCourses.has(course.id)) {
          this.courses[index] = { ...course, visible: true };
          visibleModules.add(course.module);
        } else {
          this.courses[index] = { ...course, visible: false };
        }
      });
      // make modules visible too
      _.forEach(this.modules, (module) => {
        if (module) {
          module.visible = visibleModules.has(module.id);
        }
      });
    },
  },
});
