import axios from "axios";
import i18n from "@/plugins/vueI18n";
import { parseToCamel } from "@/helpers/transformCase";

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});

api.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.global.locale.value;
  return config;
});

api.interceptors.response.use((response) => {
  // Return a successful response back to the calling service in camel case
  return parseToCamel(response);
});

export { api };
