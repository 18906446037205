import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  async scrollBehavior(to, from, savedPosition) {
    if (to.hash && !savedPosition) {
      return { selector: to.hash };
    } else if (
      from.name === "course" &&
      to.name === "planner" &&
      savedPosition
    ) {
      // when going back from course to the planner, scroll to the saved position
      return savedPosition;
    }
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    // if we navigate back from Course detail on a seminar we have to set the redirect manually,
    // because there is no route match.
    if (to.path.includes("seminar") && from.path.includes("course")) {
      return next("/courses/seminars");
    }

    // If we were not on a seminar but a specialization detail and want to navigate back to the overview,
    // we have to do it manually as well.
    return next({ name: "specializations" });
  }

  // The route has a match thus we don't have to set anything manually.
  return next();
});

export default router;
