<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-4">
          <ContentArticle :content-blocks="data.footerLeft" />
        </div>
        <div class="column is-4 has-text-centered-tablet">
          <a
            v-if="data.footerCenterUrl && data.footerCenter"
            :href="data.footerCenterUrl"
            target="_blank"
          >
            <img
              :src="data.footerCenter.file"
              width="350"
              :alt="data.footerCenter.title"
            />
          </a>
        </div>
        <div class="column is-4 has-text-right-tablet">
          <div>
            <a
              v-if="data.footerRightUrl && data.footerRight"
              :href="data.footerRightUrl"
              target="_blank"
            >
              <img
                :src="data.footerRight.file"
                width="300"
                :alt="data.footerRight.title"
              />
            </a>
          </div>
          <div class="footer-addition">
            <a
              v-if="data.footerAdditionUrl && data.footerAddition"
              :href="data.footerAdditionUrl"
              target="_blank"
            >
              <img
                :src="data.footerAddition.file"
                width="300"
                :alt="data.footerAddition.title"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { getFooterSnippets } from "@/services/snippets.service";
import ContentArticle from "@/components/ContentArticle.vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const data = ref({});

onMounted(() => {
  load();
});

const load = async () => {
  data.value = await getFooterSnippets();
  changeTarget();
};

const changeTarget = () => {
  if (!data.value.footerLeft) {
    return;
  }
  data.value.footerLeft.forEach(({ excerpt }, index) => {
    data.value.footerLeft[index].excerpt = excerpt.replace(
      new RegExp("<a ", "g"),
      '<a target="_blank"',
    );
  });
};

watch(() => locale.value, load);
</script>

<style lang="scss" scoped>
.footer-addition {
  margin-top: 4rem;
}
</style>
