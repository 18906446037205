import { api } from "@/services/api";

const getSeminars = async () => {
  const response = await api.get("/seminars/");

  return response.data;
};

const getCourses = async () => {
  const response = await api.get("/courses/");

  return response.data;
};

const getCourse = async (slug) => {
  const response = await api.get(`/courses/${slug}/`);

  return response.data;
};

const getModules = async () => {
  const response = await api.get("/modules/");

  return response.data;
};

const sendWishlistEmail = async (data) => {
  const response = await api.post("/email/", data);

  return response.data;
};

export { getSeminars, getCourses, getCourse, getModules, sendWishlistEmail };
