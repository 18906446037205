import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  // load every locale file found in @/src/locales
  const locales = import.meta.glob("../locales/*.json", { eager: true });
  const messages = {};
  // Loop through all the keys (file names)
  Object.keys(locales).forEach((key) => {
    // matched is used to get the language name from the filename (LANGUAGE.json)
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[key];
    }
  });
  return messages;
}

function loadLocaleFromBrowser() {
  const localStorageLanguage = window.localStorage?.getItem("language");
  if (localStorageLanguage && ["de", "fr"].includes(localStorageLanguage)) {
    return localStorageLanguage;
  }

  return (
    "languages" in navigator &&
    navigator.languages
      .map((lang) => {
        return lang.split("-")[0]; // 'de-CH' -> 'de'
      })
      .find((lang) => ["de", "fr"].includes(lang))
  );
}

export default createI18n({
  legacy: false,
  locale:
    localStorage.getItem("currentLocale") ||
    loadLocaleFromBrowser() ||
    import.meta.env.VITE_I18N_LOCALE ||
    "de",
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "de",
  globalInjection: true,
  messages: loadLocaleMessages(),
});
