import router from "@/router";

const handleClick = async (event) => {
  if (event.target.matches("a")) {
    event.preventDefault();
    const url = event.target.href;

    // to open internal documents
    if (url.includes(window.location.hostname + "/documents/")) {
      window.open(url, "_blank");
    } else if (url.indexOf(window.location.hostname) > -1) {
      // route local links without reload
      await router.push({ path: url });
    } else {
      // open other links in new tab
      window.open(url, "_blank");
    }
  }
};

const externalLinksHandler = {
  beforeMount: (el) => {
    el.addEventListener("click", handleClick);
  },
  beforeUnmount: (el) => {
    el.removeEventListener("click", handleClick);
  },
};

export { externalLinksHandler };
