import { api } from "@/services/api";

const getBannerSnippets = async () => {
  const response = await api.get(`/snippets/banner/banner/`);

  return response.data;
};

const getFooterSnippets = async () => {
  const response = await api.get(`/snippets/footer/footer/`);

  return response.data;
};

const getHeroSnippets = async (slug) => {
  const response = await api.get(`/snippets/hero/`, { params: { slug } });

  return response.data;
};

const getTestimonialSnippets = async (slug) => {
  const response = await api.get(`/snippets/testimonial/`, {
    params: { slug },
  });

  return response.data;
};

const getIllustrationSnippets = async () => {
  const response = await api.get(`/snippets/illustration/`);

  return response.data;
};

export {
  getBannerSnippets,
  getFooterSnippets,
  getHeroSnippets,
  getTestimonialSnippets,
  getIllustrationSnippets,
};
