<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <article v-for="contentBlock in props.contentBlocks" :key="contentBlock.id">
      <div
        v-external-links-handler
        class="content"
        v-html="contentBlock.excerpt"
      ></div>
      <div
        v-if="isVisible(contentBlock.id)"
        v-html="contentBlock.readMore"
      ></div>
      <a
        v-if="contentBlock.readMore"
        style="margin-top: 20px; display: inline-block"
        @click="toggle(contentBlock.id)"
      >
        {{
          isVisible(contentBlock.id) ? $t("general.less") : $t("general.more")
        }}...
      </a>
    </article>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  contentBlocks: {
    type: Array,
    default: () => [],
  },
});

const expandedBlocks = ref([]);

const toggle = (id) => {
  if (isVisible(id)) {
    expandedBlocks.value = expandedBlocks.value.filter(
      (blockId) => blockId !== id,
    );
  } else {
    expandedBlocks.value.push(id);
  }
};

const isVisible = (id) => {
  return expandedBlocks.value.includes(id);
};
</script>
