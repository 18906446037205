<template>
  <BDropdown class="locale-switcher-dropdown">
    <template #trigger>
      <button class="button localeSwitcher">
        <span class="is-hidden-touch">
          {{ locale.toUpperCase() }}
        </span>
        <span class="is-hidden-desktop">
          {{ $t("general.languages." + locale) }}
        </span>

        <SvgIcon name="angle-down" size="medium" />
      </button>
    </template>

    <BDropdownItem
      v-for="availableLocale in availableLocales"
      :key="availableLocale"
      :class="{ 'is-active': availableLocale === locale }"
      @click="switchLocale(availableLocale)"
    >
      {{ $t("general.languages." + availableLocale) }}
    </BDropdownItem>
  </BDropdown>
</template>

<script setup>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

import SvgIcon from "@/components/SvgIcon.vue";

const { t, locale, availableLocales } = useI18n();
const router = useRouter();
const route = useRoute();

onMounted(() => {
  setHTMLTitle();
});

const switchLocale = (newLocale) => {
  locale.value = newLocale;

  if (window.localStorage) {
    window.localStorage.setItem("language", newLocale);
  }

  router.replace({ query: { ...route.query, lang: locale.value } });

  setHTMLTitle();
};

const setHTMLTitle = () => {
  document.querySelector("title").innerText = t("general.title");
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/settings.scss";

dropdown.is-active button {
  $color: $navbar-text-color;
}

button {
  color: $navbar-text-color;
  background-color: $red !important;

  &,
  &:hover,
  &:focus {
    padding-left: 0px;
    padding-right: 0px;
    width: 60px;
    font-size: 18px;
    background-color: inherit;
    border-radius: 0px;
    font-weight: bold;
    border-color: $navbar-text-color;
  }

  &:hover,
  &:focus {
    color: darken($navbar-text-color, 20%);
    border-color: darken($navbar-text-color, 20%);
  }
  .fa-icon {
    margin-left: 5px;
  }
}

@media only screen and (max-width: ($tablet - 1px)) {
  .locale-switcher-dropdown {
    width: 100%;

    .localeSwitcher {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .dropdown-trigger,
    button,
    button:hover,
    button:active,
    button:focus {
      width: 100%;
    }

    button,
    button:hover,
    button:active,
    button:focus {
      justify-content: space-between;
      padding: 0.5rem 0.5rem;
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/css/settings.scss";
@media only screen and (max-width: ($tablet - 1px)) {
  .locale-switcher-dropdown .dropdown-trigger {
    width: 100%;
  }
}
</style>
