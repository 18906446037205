import { createApp, Fragment, h } from "vue";
import { VueRecaptchaPlugin } from "vue-recaptcha/head";
import VueClipboard from "vue3-clipboard";
import VueCarousel from "@chenfengyuan/vue-carousel";
import { createPinia } from "pinia";
import Buefy from "buefy";

import App from "@/App.vue";
import router from "@/router/";
import i18n from "@/plugins/vueI18n.js";
import vueAxeConfig from "@/plugins/vueAxe.js";
import { externalLinksHandler } from "@/directives/externalLinksHandler.js";
import Notifications from "@kyvg/vue3-notification";
import VueGtag from "vue-gtag";

import "@/assets/css/main.scss";

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;

  app.use(router);
  app.use(createPinia());
  app.use(Buefy);
  app.use(i18n);

  app.use(VueRecaptchaPlugin, {
    v2SiteKey: "6Lcj_zIUAAAAAGRjv1cMdIlrTuzwLwVRWwtcEWeC",
  });
  app.use(VueClipboard, {});
  app.use(Notifications);

  app.use(
    VueGtag,
    {
      config: {
        id: "UA-11623512-15",
      },
    },
    router,
  );

  app.directive("externalLinksHandler", externalLinksHandler);

  app.component(VueCarousel.name, VueCarousel);

  app.mount("#app");
};

// Initiate app with VueAxe if in development mode
if (process.env.NODE_ENV === "development") {
  import("vue-axe").then((res) => {
    const VueAxe = res.default;
    const VueAxePopup = res.VueAxePopup;
    app = createApp({
      render: () => h(Fragment, [h(App), h(VueAxePopup)]),
    });
    app.use(VueAxe, vueAxeConfig);
    setupApp(app);
  });
} else {
  app = createApp(App);
  setupApp(app);
}
