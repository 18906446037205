<template>
  <section class="banner">
    <b-modal
      v-model="showModal"
      :width="650"
      aria-role="dialog"
      :can-cancel="false"
      class="dg-container"
    >
      <div class="dg-content">
        <div class="message-icon" />

        <div class="message-title">
          <h2 class="">{{ bannerTitle }}</h2>
        </div>

        <div class="message-body" v-html="bannerText" />
        <div class="message-footer">
          <button class="message-footer--button" @click="handleOk">
            {{ $t("banner.confirm") }}
          </button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script setup>
import { onMounted, watch, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { getBannerSnippets } from "@/services/snippets.service";

const { locale } = useI18n();

const showModal = ref(false);
const bannerTitle = ref("");
const bannerText = ref("");
const itemName = ref("banner-popup-last-viewed");

const handleOk = () => {
  showModal.value = false;
  localStorage.setItem(itemName.value, getCurrentDay.value);
};

onMounted(() => {
  load();
});

const getCurrentDay = computed(() => {
  let date = new Date();
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
});

const load = async () => {
  const banner = await getBannerSnippets();
  bannerTitle.value = banner.title;
  bannerText.value = banner.text[0].excerpt;
  let popupDate = localStorage.getItem(itemName.value);
  if (!popupDate || popupDate !== getCurrentDay.value) {
    showModal.value = true;
  }
};

watch(() => locale.value, load);
</script>
